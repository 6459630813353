<template>
  <div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-900'>Affiliation</label>
      <input type='text' v-model='affiliation.affiliation' class='border py-1 px-2 rounded block w-80'>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-900'>Department</label>
      <edit-form-field-select
        :value='affiliation.department'
        :select-value.sync='affiliation.department'
        :meta-data='editFormFieldSelectMetaData'  />
      <!-- <input type='text' v-model='affiliation.department' class='border py-1 px-2 rounded block w-80'> -->
    </div>
    <div class='mb-8'>
      <label class='uppercase text-sm text-gray-900 mb-2'>Country</label>
      <div>
        <vue-country-code
          @onSelect='countrySelect'
          :defaultCountry='affiliation.country'
          :preferredCountries="['kr', 'us']">
        </vue-country-code>
        {{displayCountry}}
      </div>
    </div>
    <button
      class='border py-2 px-8 text-center text-white text-sm uppercase bg-gray-900 block rounded-md w-64'
      :class='needToAddAffiliationDataClass'
      :disabled='needToAddAffiliationData'
      @click='closeEditAffiliationModal'>
      Save Affiliation
    </button>
  </div>
</template>

<script>
import VueCountryCode from 'vue-country-code'
import { mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'AbstractSubmissionEditAffiliation',
  components: {
    'edit-form-field-select': () => import('@/components/edit-form/EditFormFieldSelect.vue'),
    VueCountryCode,
  },
  computed: {
    ...mapFields('abstracts', {
      affiliation: 'editingAffiliation',
    }),
    needToAddAffiliationData () {
      return !this.affiliation.affiliation ||
             !this.affiliation.department ||
             !this.affiliation.country
    },
    needToAddAffiliationDataClass () {
      return this.needToAddAffiliationData ? 'opacity-50 cursor-not-allowed' : ''
    },
    editFormFieldSelectMetaData () {
      return {
        "helperText": "",
        "placeholder": "",
        "selectableOptions": [
          {
            "id": 1,
            "title": "Cardiology",
            "value": "Cardiology"
          },
          {
            "id": 2,
            "title": "Endocrinology",
            "value": "Endocrinology"
          },
          {
            "id": 3,
            "title": "Internal Medicine",
            "value": "Internal Medicine"
          },
          {
            "id": 4,
            "title": "Family Medicine",
            "value": "Family Medicine"
          },
          {
            "id": 5,
            "title": "Nursing",
            "value": "Nursing"
          },
          {
            "id": 6,
            "title": "Basic Science",
            "value": "Basic Science"
          },
          {
            "id": 7,
            "title": "Pediatric",
            "value": "Pediatric"
          },
          {
            "id": 8,
            "title": "Food & Nutrition",
            "value": "Food & Nutrition"
          },
          {
            "id": 9,
            "title": "Neurology",
            "value": "Neurology"
          },
          {
            "id": 10,
            "title": "Nephrology",
            "value": "Nephrology"
          },
          {
            "id": 11,
            "title": "Pharmacology",
            "value": "Pharmacology"
          },
          {
            "id": 12,
            "title": "Pharmacy",
            "value": "Pharmacy"
          },
          {
            "id": 13,
            "title": "Preventive Medicine",
            "value": "Preventive Medicine"
          },
          {
            "id": 14,
            "title": "Exercise Physiology",
            "value": "Exercise Physiology"
          },
          {
            "id": 15,
            "title": "Clinical Pathology",
            "value": "Clinical Pathology"
          },
          {
            "id": 16,
            "title": "Others",
            "value": "other"
          }
        ]
      }
    },
  },
  data () {
    return {
      displayCountry: '',
    }
  },
  methods: {
    ...mapMutations('abstracts', [
      'addNewAffiliation',
      'updateAffiliation',
    ]),
    countrySelect ({name, iso2}) {
      this.displayCountry = name
      this.affiliation.country = iso2
    },
    closeEditAffiliationModal () {
      if (this.affiliation.affiliationIndex === -1) {
        this.addNewAffiliation(this.affiliation)
      } else {
        this.updateAffiliation(this.affiliation)
      }
      this.$modal.hide('edit-affiliation')
    },
  },
}
</script>
